@font-face {
  font-family: 'GT Walsheim';
  font-style: lighter;
  font-weight: 100;
  src: url('../assets/fonts/GT Walsheim/GT-Walsheim-Light.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/GT Walsheim/GT-Walsheim.otf');
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/GT Walsheim/GT-Walsheim-Medium.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/GT Walsheim/GT-Walsheim-Bold.ttf');
  font-display: swap;
}

html {
  * {
    font-family: 'GT Walsheim', Helvetica, Arial, sans-serif;
    max-width: 100%;
  }
}

#root {
  min-height: 100vh;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Override phone input */
.PhoneInput {
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
  padding-left: 16px;
  padding-top: 20px;
  margin-top: 20px;
  // &:hover {
  //   border-bottom: 2px solid #1f0d33;
  // }
  // &:focus {
  //   border-bottom: 2px solid #1f0d33;
  // }
  select {
    font-size: 14px;
    option {
      padding: 5px;
    }
  }
  .PhoneInputInput {
    font-size: 16px;
    padding: 10px;
    font-family: 'GT Walsheim';
    font-weight: 500;
    border: 0;
    &:focus {
      outline: 0;
    }
    &::placeholder {
      font-family: 'GT Walsheim';
      font-weight: 300;
      color: #000000;
    }

    &:-ms-input-placeholder {
      font-family: 'GT Walsheim';
      font-weight: 300;
      color: #000000;
    }

    &::-ms-input-placeholder {
      font-family: 'GT Walsheim';
      font-weight: 300;
      color: #000000;
    }
  }
  .PhoneInputCountrySelectArrow {
    border: 1px solid;
    border-top: 0;
    border-left: 0;
  }
}
.phoneInputError {
  font-size: 12px;
  color: #f44336;
  font-weight: bold;
}

.pac-container {
  background-color: #fff;
  position: absolute !important;
  z-index: 1000;
  font-family: Arial, sans-serif;
  box-shadow: none;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 24px;
}

.pac-container:after {
  content: none !important;
}

.pac-item {
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  height: 30px;
  font-weight: 500;
}

.pac-item:hover {
  background-color: #f6f6f6;
  span {
    color: black;
  }
  .pac-icon {
    background-image: url(../assets/location-white.png);
    filter: invert(1);
  }
}
.pac-item-query {
  font-size: 14px;
}

.pac-icon {
  background-image: url(../assets/location-white.png);
  width: 18px;
  height: 18px;
  background-size: 18px;
  margin-right: 7px;
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
  filter: invert(1);
}

html {
  background-color: #fff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.MuiPopover-root {
  z-index: 999999 !important;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  display: none;
}
